import React, { createContext, useState, useEffect } from 'react';
import * as config from '../config/config';
import { API } from '../lib/api';
import _ from 'lodash';

const language = config.language();
const langDataArray = JSON.parse(localStorage.getItem('langData'));

export const LangContext = createContext({
    language,
    switchLang: () => {},
    t: langDataArray ? (_.isUndefined(langDataArray[language]) ? {} : langDataArray[language]) : {}
});

function LangProvider (props) {
    const [webLanguage, setWebLanguage] = useState(language);
    const [langData, setLangData] = useState(langDataArray);

    useEffect( () => {
        API.getLanguages().then(response => {
            const serializedResponse = JSON.stringify(response);

            localStorage.setItem('langData', serializedResponse)

            setLangData(response)
        });
    }, []);

    const switchLang = (lang) => {
        setWebLanguage(lang);

        localStorage.setItem('webLanguage', lang);
    };

    return (
        <LangContext.Provider value={{
            language: webLanguage,
            switchLang,
            t: langData ? (_.isUndefined(langData[webLanguage]) ? {} : langData[webLanguage]) : {}
        }}>
            { props.children }
        </LangContext.Provider>
    )
}

export default LangProvider;