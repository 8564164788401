import React, { useState, useEffect, useContext } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { setAppFrameData } from "../containers/app-frame/actions";
import UI from "./ui/ui";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { LangContext } from "../providers/LangProvider";

const useStyles = makeStyles({
  container: {
    flexGrow: 1,
    height: "735px",
    paddingLeft: "118px",
    paddingTop: "141px",
  },
  notFoundErrorCode: {
    height: "197px",
    fontSize: "140px",
    color: "#000000",
  },
  notFoundText: {
    height: "25px",
    fontSize: "18px",
    color: "#000000",
  },
  toMainButton: {
    width: "125px",
    height: "40px",
    borderRadius: "4px",
    backgroundColor: "#0c5ccc",
    fontSize: "16px",
    color: "#ffffff",
    cursor: "pointer",
  },
  notFoundButtonBlock: {
    height: "40px",
    paddingTop: "48px",
    paddingBottom: "48px",
  },
});

const PageNotFound = (props) => {
  const classes = useStyles();
  const { t } = useContext(LangContext);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    props.setAppFrameData({
      withHeader: false,
    });

    return () => {
      props.setAppFrameData({
        withHeader: true,
      });
    };
  });

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      justify="flex-start"
      className={classes.container}
    >
      <RenderRedirect redirect={redirect} />
      <Grid item className={classes.notFoundErrorCode}>
        404
      </Grid>
      <Grid item className={classes.notFoundText}>
        {t.not_found_page}
      </Grid>
      <Grid item className={classes.notFoundButtonBlock}>
        <UI.Button
          className={classes.toMainButton}
          type="submit"
          onClick={() => setRedirect(true)}
          text={t.to_main}
        />
      </Grid>
    </Grid>
  );
};

const RenderRedirect = (data) => {
  if (data.redirect) {
    return <Redirect to="/" />;
  }

  return null;
};

let mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setAppFrameData,
    },
    dispatch
  );

export default compose(connect(null, mapDispatchToProps))(PageNotFound);
