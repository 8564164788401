import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  ordersTitle: {
    color: "#212121",
    fontSize: "34px",
    fontWeight: "500",
    paddingTop: "16px",
  },
  ordersItemsContainer: {
    minHeight: "360px",
    order: 1,

    "@media (max-width:960px)": {
      order: 2,
    },
  },
  searchFieldBlock: {
    borderRadius: "8px",
    boxShadow: "0 4px 40px 0 rgba(0, 0, 0, 0.08)",
    backgroundColor: "#ffffff",
    order: 2,
    position: "sticky",
    top: "82px",

    "@media (max-width:960px)": {
      order: 1,
    },
  },
  orderMainBlock: {
    width: "100%",
  },
  orderSubmitBlock: {
    float: "right",
  },
  actionButtonContainer: {
    width: "100%",
    borderTop: "2px #f2f4f5 solid",
    marginTop: "24px",
  },
  actionButtonBlock: {
    paddingTop: "24px",
    flexWrap: "wrap",
    display: "flex",
  },
  actionSubmitBlock: {
    paddingBottom: "8px",
    paddingRight: "8px",

    "& button": {
      padding: "6px 12px",
      fontSize: "12px",
      height: "auto",
    },
  },
  searchSingleItem: {
    width: "100%",
  },
  searchDoubleItem: {
    width: "50%",
  },
  itemBlock: {
    minHeight: "68px",
  },
  orderStatusSearchBlock: {
    minHeight: "76px",
  },
  dateFromBlock: {
    minHeight: "76px",
  },
  dateToBlock: {
    minHeight: "76px",
  },
  ordersMainContainer: {
    position: "relative",
  },
  emptyOrdersBox: {
    height: "360px",
  },
  emptyOrdersBoxTitle: {
    paddingTop: "140px",
    color: "#7e878b",
    fontWeight: "500",
    fontSize: "24px",
    textAlign: "center",
  },
  searchFieldsContainer: {
    alignSelf: "flex",
    paddingTop: "81px !important",
    order: 2,

    "@media (max-width: 960px)": {
      width: "100%",
      order: 1,
      paddingTop: "24px !important",
    },
  },
  searchFields: {
    padding: "16px",
  },
  ordersBlock: {
    paddingTop: "24px",

    "@media (max-width: 960px)": {
      paddingTop: "16px",
    },
  },
  ordersBlockTitle: {
    fontSize: "24px",
    fontWeight: "500",
    color: "#212121",
  },
  orderContainer: {
    marginTop: "16px",
    borderRadius: "8px",
    backgroundColor: "#ffffff",
  },
  cursorPointer: {
    cursor: "pointer",
  },
  orderBlock: {
    padding: "16px",
  },
  oderIdBlock: {
    fontSize: "18px",
    fontWeight: "500",
    color: "#212121",
  },
  routesBlock: {
    fontSize: "18px",
    fontWeight: "500",
    color: "#212121",
  },
  amountContainer: {
    height: "100%",

    "@media (max-width: 1280px)": {
      padding: "8px 0",
    },
  },
  amountBlock: {
    fontSize: "24px",
    fontWeight: "500",
    color: "#212121",
    textAlign: "right",
  },
  timestampBlock: {
    fontSize: "16px",
    color: "#7e878b",
  },
  departuresBlock: {
    fontSize: "16px",
    color: "#7e878b",
  },
  statusBlock: {
    fontSize: "16px",
    color: "#7e878b",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  },
  orderStatusBlock: {
    display: "flex",
    alignItems: "center",

    "@media (max-width: 600px)": {
      paddingBottom: "8px",
    },
  },
  statusStringBlock: {
    fontSize: "16px",
    paddingLeft: "12px",
    display: "inline-block",
  },
  circle: {
    width: "8px",
    height: "8px",
    borderRadius: "8px",
  },
  statusString: {
    position: "relative",
    display: "flex",
    alignItems: "center",
  },
  span: {
    paddingRight: "8px",
  },
  arrowIcon: {
    height: "24px",
    padding: "0 5px",
    position: "relative",
  },
  helpContainer: {
    float: "right",
    paddingTop: "70px",
  },
  paginationContainer: {
    order: 3,
  },
  paginationItemsBlock: {
    cursor: "pointer",
    display: "inline-table",
    fontSize: "20px",
    fontWeight: "500",
    color: "#7e878b",
    paddingTop: "16px",
    paddingBottom: "32px",
  },
  paginationButtonBlock: {
    cursor: "pointer",
    color: "#7e878b",
    fontSize: "18px",
    fontWeight: "500",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "16px",
    paddingBottom: "32px",

    "&:focus": {
      outline: "none",
    },
  },
  paginationInline: {
    display: "inline-table",
  },
  arrowUpIcon: {
    position: "relative",
    bottom: "5px",
    right: "100px",
  },
  paginationIcon: {
    position: "relative",
    top: "3px",
    display: "inline-table",
  },
  paginationInlineBefore: {
    display: "inline-table",
    paddingRight: "16px",
  },
  paginationInlineNext: {
    display: "inline-table",
    paddingRight: "4px",
    paddingLeft: "8px",
  },
  selectedPage: {
    width: "36px",
    height: "36px",
    borderRadius: "4px",
    backgroundColor: "#0c5ccc",
    display: "inline-table",
    marginRight: "8px",
    fontSize: "20px",
    color: "#ffffff",
    textAlign: "center",
  },
  notSelectedPage: {
    width: "36px",
    height: "36px",
    display: "inline-table",
    marginRight: "8px",
    fontSize: "20px",
    textAlign: "center",
  },
  pageItem: {
    display: "inline-block",
    paddingTop: "4px",
  },
  emptyOrdersContainer: {
    height: "270px",
  },
  emptyOrdersBlock: {
    paddingTop: "100px",
    textAlign: "center",
  },
  emptyOrdersTitle: {
    color: "#7e878b",
    fontWeight: "500",
    fontSize: "24px",
  },
  notFoundButtonBlock: {
    height: "40px",
    textAlign: "center",
    paddingTop: "18px",
  },
  toMainButton: {
    width: "125px",
    height: "40px",
    borderRadius: "4px",
    backgroundColor: "#0c5ccc",
    fontSize: "16px",
    color: "#ffffff",
    cursor: "pointer",
  },
  searchFieldInput: {
    height: "48px",
  },
  searchIcon: {
    paddingLeft: "8px",
    color: "#b9c1c5",
  },
  test1: {
    width: "100%",
  },
});
