import React, { useState, useEffect, useContext } from "react";
import Chart from "react-google-charts";
import loader from "../../../../images/loader.gif";
import { makeStyles } from "@material-ui/core/styles";
import { LangContext } from "../../../../providers/LangProvider";
import { normalizePrice } from "../../../../lib/normalize";

const pieOptions = {
  title: "",
  pieHole: 0.7,
  backgroundColor: "none",
  slices: [{ color: "#0c5ccc" }, { color: "#86cde7" }],
  legend: "none",
  pieSliceText: "none",
};

const useStyles = makeStyles({
  root: {
    position: "relative",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  absoluteBlock: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    fontSize: "10px",
    fontWeight: "500",
  },
  loadingSniper: {
    width: "80px",
    height: "80px",
    backgroundImage: `url(${loader})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "80px",
    position: "absolute",
  },
});

export default function ChartDoughnut(props) {
  const classes = useStyles();
  const { t } = useContext(LangContext);
  const [loading, setLoading] = useState(false);
  const { otb, debt, currency } = props;

  return (
    <div className={classes.root}>
      {loading && (
        <div className={classes.absoluteBlock}>
          {normalizePrice(otb)} {currency}
        </div>
      )}
      <Chart
        chartType="PieChart"
        data={[
          ["otb", "debt"],
          [t.otb, otb],
          [t.debt, debt],
        ]}
        options={pieOptions}
        width="200px"
        height="200px"
        ready={() => {
          setLoading(true);
        }}
        loader={<LoaderChart setLoading={setLoading} />}
      />
    </div>
  );
}

const LoaderChart = (props) => {
  const classes = useStyles();
  const { setLoading } = props;

  useEffect(() => {
    return () => {
      setLoading(true);
    };
  }, []);

  return <div className={classes.loadingSniper} />;
};
