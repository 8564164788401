import { makeStyles } from "@material-ui/core/styles";
import bgImage from "../../../images/big-image.png";

export const useStyles = makeStyles((theme) => ({
  menuBlock: {
    fontSize: "14px",
    padding: "10px 0 10px 32px",
    cursor: "pointer",
    position: "relative",
    display: "flex",
    alignItems: "center",
  },
  menuLabel: {
    display: "inline-table",
    textAlign: "left",
    cursor: "pointer",
  },
  menuContainer: {
    color: "#000000",
    padding: 0,
    fontSize: "17px",
    backgroundColor: "#ffffff",
    boxShadow:
      "0 4px 16px 0 rgba(0, 0, 0, 0.12), 0 4px 4px 0 rgba(0, 0, 0, 0.08)",
    borderRadius: "4px",
    width: "280px",
    textAlign: "left",
    position: "absolute",
    right: -20,
    top: "100%",
    zIndex: "1",
  },
  menuItem: {
    padding: "10px",
  },
  link: {
    color: "#000000",
    display: "block",
  },
  menuBalanceItem: {
    padding: "4px 0 4px 8px",
  },
  linkItem: {
    color: "#000000",
    display: "block",
    height: "25px",
  },
  item: {
    paddingTop: "3px",
    paddingLeft: "7px",
    display: "block",
  },
  b2cHeaderMenuItem: {
    display: "flex",
    alignItems: "center",
    fontSize: "18px",
    lineHeight: 1.1,
    padding: "23px 0 23px 32px",
    cursor: "pointer",
  },
  agents: {
    display: "flex",
    alignItems: "center",
    fontSize: "18px",
    lineHeight: 1.1,
    cursor: "pointer",
  },
  agentAndSearchButtons: {
    display: "flex",
  },
  headerMenuItem: {
    display: "flex",
    alignItems: "center",
    fontSize: "18px",
    lineHeight: 1,
    padding: "18px 0 18px 32px",
    cursor: "pointer",
  },
  balanceTitle: {
    fontSize: "14px",
  },
  balanceMobile: {
    height: "35px",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    cursor: "pointer",
    paddingLeft: "16px",
    "&:hover": {
      background: "#86cde7",
    },
    fontSize: "15px",
  },
  expandMobile: {
    fontSize: "15px",
  },
  balance: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    cursor: "pointer",
    padding: "16px",
    boxSizing: "border-box",
    "&:hover": {
      background: "#86cde7",
    },
  },
  balanceContainer: {
    color: "#000000",
    fontSize: "12px",
    backgroundColor: "#ffffff",
    boxShadow:
      "0 4px 16px 0 rgba(0, 0, 0, 0.12), 0 4px 4px 0 rgba(0, 0, 0, 0.08)",
    borderRadius: "4px",
    width: "245px",
    textAlign: "left",
    position: "absolute",
    top: "100%",
    zIndex: "1",
  },
  menuDropdown: {
    color: "#000000",
    fontSize: "16px",
    backgroundColor: "#ffffff",
    boxShadow:
      "0 4px 16px 0 rgba(0, 0, 0, 0.12), 0 4px 4px 0 rgba(0, 0, 0, 0.08)",
    width: "280px",
    textAlign: "left",
    position: "absolute",
    right: "0px",
  },
  closeMenuIconBlock: {
    width: "100%",
    textAlign: "right",
    cursor: "pointer",
  },
  closeMenuIcon: {
    paddingRight: "8px",
    paddingTop: "8px",
    width: "1.2em",
    height: "1.2em",
  },
  mobileMenuBalance: {
    width: "100%",
    padding: "5px",
    paddingLeft: theme.spacing(3.5),
    fontSize: "12px",
  },
  mobileMenuClientName: {
    fontSize: "18px",
    width: "100%",
    padding: "4px 16px",
    fontWeight: "500",
    boxSizing: "border-box",
  },
  mobileMenuItem: {
    width: "100%",
    cursor: "pointer",
    padding: "16px",
    boxSizing: "border-box",
    "&:hover": {
      background: "#86cde7",
    },
  },
  agency: {
    width: "100%",
    cursor: "pointer",
    paddingLeft: "16px",
    boxSizing: "border-box",
    "&:hover": {
      background: "#86cde7",
    },
  },
  linkToMainPage: {
    display: "block",
    color: "#000000",
  },
  line: {
    width: "100%",
    height: "2px",
    backgroundColor: "#eeeeec",
  },
  smallLineBlock: {
    width: "50px",
  },
  fullLineBlock: {
    width: "100%",
  },
  backdrop: {
    zIndex: 2,
    color: "#fff",
  },
  mobileMenuContainer: {
    position: "sticky",
    top: 0,
    left: 0,
    zIndex: 50,
    width: "100%",
    display: "block",
    transition: "transform .3s ease",

    "@media (min-width:768px)": {
      display: "none",
    },
  },
  desktopMenuContainer: {
    position: "sticky",
    top: 0,
    left: 0,
    zIndex: 50,
    width: "100%",
    display: "none",
    transition: "transform .3s ease",
    background: `url(${bgImage})`,

    "@media (min-width:768px)": {
      display: "block",
    },
  },
  menuContainerHidden: {
    transform: "translateY(-65px)",
    transition: "transform .3s ease",
  },
  textStart: {
    lineHeight: 1,
  },
  logoBlock: {
    position: "relative",
    display: "flex",
    alignItems: "center",
  },
  clientCode: {
    textTransform: "lowercase",
    fontSize: "14px",
    paddingLeft: "5px",
    color: "#696969",
  },
  client: {
    display: "flex",
    flexWrap: "wrap",
    textTransform: "lowercase",
    fontSize: "13px",
    color: "#696969",
  },
  cCode: {
    marginRight: "5px",
  },
  clientType: {
    fontSize: "13px",
    color: "#696969",
  },
  clientName: {
    lineHeight: 1,
    display: "flex",
    alignItems: "center",
    marginTop: "2px",
  },
  clientBlock: {
    marginLeft: "10px",
  },
  partnerName: {
    height: "50px",
    borderTop: "1px solid darkgrey",
    borderBottom: "1px solid darkgrey",
  },
  partnerBlock: {
    display: "flex",
    alignItems: "center",
    marginLeft: "5px",
  },
  partnerMobileBlock: {
    display: "flex",
    alignItems: "center",
    marginLeft: "13px",
  },
  circleIcon: {
    width: "45px",
    height: "45px",
  },
  buildingIcon: {
    height: "35px",
    width: "35px",
    marginTop: "7px",
  },
  root: {
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  collapse: {
    padding: "0 0 10px 0",
  },
  nestedMobile: {
    paddingTop: "7px",
    display: "block",
    paddingLeft: theme.spacing(3.5),
    height: "30px",
    color: "#696969",
    fontSize: "15px",
    "&:hover": {
      background: "#86cde7",
      borderRadius: "4px",
    },
  },
  nested: {
    paddingTop: "7px",
    display: "block",
    paddingLeft: theme.spacing(1.5),
    height: "25px",
    color: "#696969",
    fontSize: "15px",
    "&:hover": {
      background: "#86cde7",
      borderRadius: "4px",
    },
  },
  triangle: {
    float: "right",
    marginTop: "-8px",
    marginRight: "32px",
    width: "20px",
    height: "8px",
    backgroundColor: "#ffffff",
    clipPath: "polygon(50% 0, 0 100%, 100% 100%)",
  },
  item1: {
    marginTop: "5px",
    height: "30px",
    padding: "5px 0 0 7px",
    "&:hover": {
      background: "#86cde7",
      borderRadius: "4px",
    },
  },
  logout: {
    height: "30px",
    padding: "5px 0 0 7px",
    "&:hover": {
      background: "#86cde7",
      borderRadius: "4px",
    },
  },
}));
