import React, { useContext } from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { setAppFrameData } from "../containers/app-frame/actions";
import UI from "./ui/ui";
import { withAuthRedirect } from "../hoc/withAuthRedirect";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Footer from "../containers/common/footer/index";
import { LangContext } from "../providers/LangProvider";

const useStyles = makeStyles({
  container: {
    flexGrow: 1,
    height: "735px",
    paddingLeft: "118px",
    paddingTop: "141px",
  },
  errorCode: {
    height: "197px",
    fontSize: "140px",
    color: "#000000",
  },
  errorText: {
    width: "596px",
    height: "25px",
    fontSize: "18px",
    color: "#000000",
    marginBottom: "24px",
  },
  toMainButton: {
    width: "125px",
    height: "40px",
    borderRadius: "4px",
    backgroundColor: "#0c5ccc",
    fontSize: "16px",
    color: "#ffffff",
    cursor: "pointer",
  },
  errorButtonBlock: {
    height: "40px",
    paddingTop: "48px",
    paddingBottom: "48px",
  },
});

const ErrorPage = (props) => {
  const classes = useStyles();
  const { t } = useContext(LangContext);

  const handleOnClick = () => {
    props.history.push({
      pathname: "/",
    });

    window.location.reload();
  };

  return (
    <Box>
      <Grid
        container
        spacing={0}
        direction="column"
        justify="flex-start"
        className={classes.container}
      >
        <Grid item className={classes.errorCode}>
          500
        </Grid>
        <Grid item className={classes.errorText}>
          {t.error_500_text}
        </Grid>
        <Grid item className={classes.errorButtonBlock}>
          <UI.Button
            className={classes.toMainButton}
            type="submit"
            onClick={() => handleOnClick()}
            text={t.to_main}
          />
        </Grid>
      </Grid>
      <Footer />
    </Box>
  );
};

let mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setAppFrameData,
    },
    dispatch
  );

export default compose(
  connect(null, mapDispatchToProps),
  withAuthRedirect
)(ErrorPage);
