import React, { useContext } from "react";
import { Field, reduxForm } from "redux-form";
import { useStyles } from "../containers/auth/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { compose } from "redux";
import { connect } from "react-redux";
import { required, email, minLength6 } from "../lib/validate";
import { LinkText, StyledLoginButton } from "../styles/auth/index";
import ResponsiveFormField from "./ui/components/ResponsiveFormField/ResponsiveFormField";
import ResponsiveInput from "./ui/components/ResponsiveInput/ResponsiveInput";
import { LangContext } from "../providers/LangProvider";
import SelectLanguage from "./common/ui/SelectLanguage";

function LoginForm(props) {
  const classes = useStyles();
  const { t } = useContext(LangContext);
  const { errorCode, authError } = props;

  return (
    <Grid container className={classes.rootContainer}>
      <form
        onSubmit={props.handleSubmit(props.onSend)}
        className={classes.form}
      >
        <Box className={classes.authBlock}>
          <Grid item className={classes.loginTitleBlock}>
            <Box className={classes.loginTitle}>{t.login_title_text}</Box>
          </Grid>
          <Grid item className={classes.inputBlock}>
            <Box className={classes.inputItem}>
              <Field
                name="login"
                component={ResponsiveFormField}
                defaultValue=""
                label={t.login_text}
                validate={[required, email]}
                fieldError={checkAuthError("12", errorCode, authError)}
                onChange={() => resetLoginError("login", props)}
                isPadding={true}
                InputComponent={ResponsiveInput}
              />
            </Box>
          </Grid>
          <Grid item className={classes.inputBlock}>
            <Box className={classes.inputItem}>
              <Field
                name="password"
                type="password"
                component={ResponsiveFormField}
                defaultValue=""
                label={t.password_text}
                validate={[required, minLength6]}
                fieldError={checkAuthError("11", errorCode, authError)}
                onChange={() => resetLoginError("password", props)}
                isPadding={true}
                InputComponent={ResponsiveInput}
              />
            </Box>
          </Grid>
          <Grid item>
            <Box className={classes.linksBlock}>
              <LinkText text={t.forgot_password_text} to="#" />
              <LinkText text={t.registration_text} to="#" />
            </Box>
          </Grid>
          <Grid item className={classes.buttonBlock}>
            <StyledLoginButton type="submit">{t.enter_text}</StyledLoginButton>
          </Grid>
          <Grid item className={classes.loginAgreementTextBlock}>
            <Box className={classes.loginAgreementText}>{t.agreement_text}</Box>
          </Grid>
          <Box className={classes.langBLock}>
            <SelectLanguage withoutTitle={true} fontSize="16px" />
          </Box>
        </Box>
      </form>
    </Grid>
  );
}

const checkAuthError = (code, errorCode, authError) => {
  if (errorCode === code) {
    return authError;
  }
};

const resetLoginError = (type, props) => {
  const { untouch, form, resetLoginError } = props;

  untouch(form, type);
  resetLoginError();
};

const mapStateToProps = (state) => ({
  errorCode: state.setAuthError.error_code,
  authError: state.setAuthError.error_desc,
});

export default compose(
  connect(mapStateToProps),
  reduxForm({ form: "login" })
)(LoginForm);
