import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  checked: {
    color: "#0c5ccc !important",
  },
});

export default function CheckboxUI(props) {
  const classes = useStyles();

  return <Checkbox classes={{ checked: classes.checked }} {...props} />;
}
