import styled from "styled-components";
import { StyledInput } from "../common/index";
import arrowMCompare from "../../images/arrow-m-compare.png";
import iconsPlus from "../../images/icons-plus.png";
import iconsClose from "../../images/icons-close.png";
import { DropdownItem } from "../common/ui/dropdown/index";

export const SearchContainer = styled.div`
  margin: auto;
  position: relative;
  padding: ${(props) => (props.isResultPage ? "8px 0" : "160px 0 64px")};
  text-align: center;
`;

export const StyledCalendarInput = styled(StyledInput)`
  padding-left: 16px;
  cursor: pointer;
  height: ${(props) => props.heightSize || "52px"};
  border-radius: 4px;
  border: solid ${(props) => (props.borderColor ? "2px" : "1px")}
    ${(props) => props.borderColor || "#34ccec"};
  background-color: #ffffff;
  font-size: 18px;
  color: #212121;
  box-sizing: border-box;
  width: 100%;

  ${(props) =>
    props.isFocus &&
    `
        border: solid 1px #0c5ccc;
    `}
`;

export const StyledAirportInput = styled(StyledInput)`
  padding-left: 16px;
  width: 100%;
  box-sizing: border-box;
`;

export const DropdownArea = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
`;

export const PassengersLink = styled.div`
  text-align: left;
  padding: 0;

  @media (min-width: 600px) {
    padding: 10px 0;
  }
`;

export const AddRouteButtonStyled = styled.button`
  height: 32px;
  border-radius: 4px;
  background-color: #ffffff;
  color: #212121;
  font-size: 16px;
  background-image: url(${iconsPlus});
  background-repeat: no-repeat;
  background-position: left;
  background-size: 24px;
  padding: 2px 2px 2px 20px;
  margin: 0;
  cursor: pointer;

  :focus {
    outline: none;
  }

  :hover {
    background-color: #f2f4f5;
  }

  :active {
    background-color: #34ccec;
  }
`;

export const SwitchButtonStyled = styled.button`
  cursor: pointer;
  width: 32px;
  height: 32px;
  background-color: #ffffff;
  background-image: url(${arrowMCompare});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 20px;
  border-radius: 4px;
  border: none;
  position: absolute;
  transform: rotate(90deg);
  right: 15px;
  top: 50%;
  margin-left: -16px;
  margin-top: -16px;
  z-index: 1;

  @media (min-width: 960px) {
    left: 50%;
    transform: rotate(0deg);
  }

  :hover {
    background-color: #f2f4f5;
  }

  :active {
    background-color: #b9c1c5;
  }

  :focus {
    outline: none;
  }
`;

export const CodeIata = styled.div`
  position: absolute;
  right: 15px;
  top: 20px;
  right: 40px;
  color: #b0bec5;
  font-size: 14px;

  @media (min-width: 320px) {
    right: 25px;
  }

  @media (min-width: 345px) {
    right: 30px;
  }

  @media (min-width: 375px) {
    right: 34px;
  }

  @media (min-width: 410px) {
    right: 20px;
  }

  @media (min-width: 414px) {
    right: 36px;
  }

  @media (min-width: 576px) {
    right: 20px;
  }
`;

export const RemoveButtonStyled = styled.div`
  width: 32px;
  height: 32px;
  margin-top: 0;
  filter: invert(1);
  cursor: pointer;
  background-image: url(${iconsClose});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 24px;
  cursor: pointer;
`;

export const SelectSearchStyled = {
  container: (base, state) => ({
    ...base,
    border: state.isFocused ? null : null,
  }),

  singleValue: (base) => ({
    ...base,
    color: "#ffffff",
    fontSize: 16,
  }),

  control: (styles, state) => ({
    ...styles,
    height: 56,
    cursor: "pointer",
    fontSize: 18,
    width: 190,
    border: "none",
    backgroundColor: "initial",
    backgroundImage: "none",
  }),

  valueContainer: (base) => ({
    ...base,
    padding: 0,
    paddingLeft: 0,
  }),

  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),

  dropdownIndicator: (base, state) => ({
    ...base,
  }),

  option: (provided, state) => ({
    ...provided,
    textAlign: "left",

    backgroundColor: state.isDisabled
      ? null
      : state.isSelected
      ? "#86cde7"
      : state.isFocused
      ? "#86cde7"
      : null,

    color: "#212121",
    fontSize: 18,
  }),

  menu: (base) => ({
    backgroundColor: "#ffffff",
    borderRadius: 4,
    border: "solid 1px #34ccec",
  }),
};

export const RouteBlockStyled = styled.div`
  width: 100%;
  padding-top: ${(props) => (props.withPaddingTop ? "16px" : 0)};
`;

export const StyledPassengersContainer = styled.div`
  position: relative;
`;

export const StyledPassengersBlock = styled.div`
  border-radius: 4px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.12), 0 4px 4px 0 rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
  padding: 10px;
  width: 280px;
  position: absolute;
  z-index: 1;
`;

export const StyledNumberInputLabelContainer = styled.div`
  float: left;
  text-align: left;
`;

export const StyledNumberInputMainLabel = styled.div`
  font-size: 18px;
  color: #000000;
  font-weight: 500;
`;

export const StyledNumberInputSecondLabel = styled.div`
  font-size: 12px;
  color: #7e878b;
`;

export const DropdownAirportItem = styled(DropdownItem)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: ${(props) => (props.isMaim ? "#ffffff" : "#fafafc")};
`;

export const MainItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-left: ${(props) => (props.isMaim ? "16px" : "32px")};
  padding: 4px 0;
`;

export const AirportNamesBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;

export const AirportItem = styled.div`
  font-size: 18px;
  color: #000000;
  line-height: 1;
  text-align: left;
`;

export const CountryMame = styled.div`
  font-size: 12px;
  color: #b0bec5;
`;

export const CodeItem = styled.div`
  float: right;
  margin-right: 16px;
  color: #b0bec5;
  font-size: 14px;
`;

export const AirportFieldContainer = styled.div`
  position: relative;
`;

export const StyledPassengerLink = styled.div`
  display: inline-flex;
  align-items: center;
  font-size: 16px;
  color: #ffffff;
  cursor: pointer;
  padding: 5px 8px 5px 0;
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
`;

export const FlightTypeBlockStyled = styled.div`
  padding: 0;

  @media (min-width: 600px) {
    padding: 10px 0;
  }
`;

export const FlightClassBlockStyled = styled.div`
  padding: 0;

  @media (min-width: 600px) {
    padding: 10px 0;
  }
`;
