import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import _ from "lodash";
import FormControlLabel from "@material-ui/core/FormControlLabel";

export default function RadioButtonGroup(props) {
  const { items, onChange, value, disabled } = props;

  const handleChange = (event) => {
    onChange(event.target.value);
  };

  const CustomRadio = withStyles({
    root: {
      color: "#0c5ccc",
      "&$checked": {
        color: "#0c5ccc",
      },
      "&$disabled": {
        color: "#34ccec",
      },
      "&:hover": {
        color: "#0c5ccc",
      },
    },
    disabled: {},
    checked: {},
  })((props) => <Radio disableRipple {...props} />);

  return (
    <FormControl>
      <RadioGroup value={value} onChange={handleChange}>
        {_.map(items, function (item, key) {
          return (
            <FormControlLabel
              key={key}
              value={item.value}
              control={<CustomRadio />}
              label={item.label}
              disabled={disabled}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
}
