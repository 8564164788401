import { makeStyles } from "@material-ui/core/styles";
import TKPLogo from "../../../images/mainLogo/others/logo-TKP.png";
import logosPaymentSystems from "../../../images/otherLogos/logosPaymentSystems.png";
import masterCardLogo from "../../../images/otherLogos/masterCard.png";
import mirLogo from "../../../images/otherLogos/mir.png";
import unitellerLogo from "../../../images/otherLogos/uniteller.png";
import visaLogo from "../../../images/otherLogos/visa.png";

export const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  footerBlock: {
    flexShrink: 0,
  },
  FooterColumn: {
    color: "#212121",
    fontSize: "18px",
    paddingBottom: "34px",
    "@media (min-width:1275px)": {
      paddingBottom: "34px",
    },
  },
  FooterColumn_2: {
    color: "#212121",
  },
  FooterColumn_3: {
    paddingTop: "3px",
    justifyContent: "flex-end",

    "@media (max-width:940px)": {
      justifyContent: "flex-start",
    },
    "@media (min-width:321px)": {
      fontSize: "16px",
    },
    "@media (min-width:376px)": {
      fontSize: "18px",
    },
  },
  FooterPhoneRow: {
    fontSize: "24px",
    fontWeight: 500,
    color: "#0c5ccc",
    paddingBottom: "10px",

    "@media (min-width:321px)": {
      fontSize: "30px",
    },

    "@media (min-width:376px)": {
      paddingBottom: "20px",
      fontSize: "34px",
    },
  },
  contactBlock: {
    fontSize: "15px",

    "@media (min-width:321px)": {
      fontSize: "16px",
    },
    "@media (min-width:376px)": {
      fontSize: "18px",
    },
  },
  SelectLanguageBlock: {
    marginLeft: "16px",
  },
  MailLink: {
    color: "#0c5ccc",
    marginLeft: "8px",
  },
  socialIcons: {
    marginTop: "10px",
  },
  facebookLink: {
    color: "#333333",
    marginRight: "10px",
    "&:hover": {
      color: "#3b5999",
    },
  },
  instagramLink: {
    color: "#333333",
    marginRight: "10px",
    "&:hover": {
      color: "#e4405f",
    },
  },
  telegramLink: {
    color: "#333333",
    "&:hover": {
      color: "#0077b5",
    },
  },
  FooterLogoBlock: {
    height: "24px",
    justifyContent: "flex-start",
    width: "100%",
  },
  AgreementBlock: {
    fontSize: "12px",
    color: "#90a4ae",
    width: "90%",
    float: "left",
    paddingTop: "25px",
    paddingBottom: "15px",
  },
  paymentSystemsLogoBlock: {
    position: "relative",
    paddingTop: "16px",
  },
  visaItem: {
    position: "relative",
    top: "-16px",
    paddingLeft: "6px",
  },
  masterCarItem: {
    position: "relative",
    top: "-16px",
    paddingLeft: "2px",
  },
  mirItem: {
    position: "relative",
    top: "-2px",
  },
  unitellerItem: {
    position: "relative",
    top: "-3px",
    paddingLeft: "6px",
  },
  TKPItem: {
    position: "relative",
    top: "-3px",
    paddingLeft: "6px",
  },
  TKPLogo: {
    backgroundImage: `url(${TKPLogo})`,
    width: "48px",
    height: "20px",
    backgroundSize: "48px 20px",
  },
  visaLogo: {
    backgroundImage: `url(${visaLogo})`,
    width: "48px",
    height: "48px",
    backgroundSize: "48px 48px",
  },
  masterCardLogo: {
    backgroundImage: `url(${masterCardLogo})`,
    width: "48px",
    height: "48px",
    backgroundSize: "48px 48px",
  },
  mirLogo: {
    backgroundImage: `url(${mirLogo})`,
    width: "40px",
    height: "20px",
    backgroundSize: "40px 20px",
  },
  unitellerLogo: {
    backgroundImage: `url(${unitellerLogo})`,
    width: "46px",
    height: "24px",
    backgroundSize: "46px 24px",
  },
  paymentSystemsLogo: {
    backgroundImage: `url(${logosPaymentSystems})`,
    width: "200px",
    height: "54px",
    backgroundSize: "200px 54px",
  },
  staticPageLinkBlock: {
    width: "50%",

    "@media (max-width:1280px)": {
      width: "100%",
      paddingLeft: "50px",
    },
    "@media (max-width:960px)": {
      width: "50%",
      paddingLeft: "0",
    },
    "@media (max-width:500px)": {
      width: "100%",
    },
  },
  staticPageLink: {
    fontSize: "15px",
    color: "#212121",
    textDecoration: "none",
    cursor: "pointer",

    "@media (min-width:321px)": {
      fontSize: "16px",
    },
    "@media (min-width:376px)": {
      fontSize: "18px",
    },
  },
  circularProgress: {
    color: "#86cde7",
  },
  staticPagesBLock: {
    "@media (max-width:1280px)": {
      flexDirection: "column",
    },
    "@media (max-width:960px)": {
      flexDirection: "row",
      paddingBottom: "10px",
    },
    "@media (max-width:500px)": {
      flexDirection: "column",
    },
  },
});
