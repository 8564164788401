import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Select, MenuItem, Input, InputLabel } from "@material-ui/core";
import device from "current-device";
import NativeSelectStatelessUI from "../NativeSelectStatelessUI/NativeSelectStatelessUI";

function SelectFormUI(props) {
  const {
    input,
    items,
    color,
    fontSize,
    label,
    width,
    height,
    onClick,
    disabled,
    value,
  } = props;

  const useCustomStyles = makeStyles({
    formControl: {
      position: "relative",
    },
    inputLabel: {
      fontSize: 12,
      color: "#9da8ae",
      position: "absolute",
      top: 8,
      left: 16,
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1.41",
      letterSpacing: "normal",
    },
  });

  const useStyles = makeStyles({
    root: {
      overflow: "hidden",
      backgroundColor: "transparent",
      color: color ? color : "#000000",
      fontSize: fontSize ? fontSize : 16,
      borderRadius: 4,
      border: "1px solid #ced4da",
      padding: "31px 0 9px 16px",
      width: width ? width : 280,
      height: height ? height : 18,
    },
    select: {
      "&:focus": {
        backgroundColor: "transparent",
        borderRadius: 4,
      },
      "&:hover": {
        backgroundColor: "transparent",
        borderRadius: 4,
      },
    },
    icon: {
      color: color ? color : "#000000",
      top: `calc(50% - 5px)`,
    },
  });

  const inputStyles = makeStyles({
    root: {
      display: "flex",
    },
  });

  const StyledMenuItem = withStyles((theme) => ({
    root: {
      "&:hover": {
        backgroundColor: "#86cde7",
      },
    },
  }))(MenuItem);

  const classes = useCustomStyles();

  const styles = useStyles();

  const inputClasses = inputStyles();

  const desktopSelect = () => (
    <Select
      input={<Input {...input} classes={inputClasses} />}
      disabled={disabled}
      onClick={onClick}
      disableUnderline={true}
      inputProps={{
        classes: styles,
      }}
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left",
        },
        getContentAnchorEl: null,
      }}
    >
      {items.map(({ label, value }) => (
        <StyledMenuItem disableRipple key={value} value={value}>
          {label}
        </StyledMenuItem>
      ))}
    </Select>
  );

  return (
    <div className={classes.formControl}>
      {label && (
        <InputLabel className={classes.inputLabel} focused={false}>
          {label}
        </InputLabel>
      )}
      {device.type === "desktop" ? (
        desktopSelect()
      ) : (
        <NativeSelectStatelessUI
          input={input}
          items={items}
          value={value}
          onClick={onClick}
        />
      )}
    </div>
  );
}

export default SelectFormUI;
