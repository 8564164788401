import styled from "styled-components";

export const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.12), 0 4px 4px 0 rgba(0, 0, 0, 0.08);
  background-color: #fafafc;
  border-radius: 4px;
  position: absolute;
  z-index: 2;
  padding: 4px 0 4px 0;
  left: 0;
`;

export const DropdownItem = styled.div`
  ${(props) =>
    props.isSelected &&
    `
        background-color: #86cde7;
    `}

  cursor: pointer;

  :hover {
    background: #86cde7;
  }

  :active {
    background-color: #86cde7;
  }
`;

export const ChaildItem = styled.div`
  margin-left: 16px;
  float: left;
`;
