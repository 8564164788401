import bgImage from "../../images/big-image.png";
import styled from "styled-components";
import { createGlobalStyle } from "styled-components";
import Link from "../../components/ui/components/Link/Link";
import DehazeIcon from "@material-ui/icons/Dehaze";

export const GlobalStyle = createGlobalStyle`
  body {
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.41;
    letter-spacing: normal;
    font-family: Roboto, sans-serif;
    caret-color: #0c5ccc;
    background-color: #f2f4f5;
    height: 100%;
  }
`;

export const AppContainer = styled.div({
  position: "relative",
  flex: "1 0 auto",
  zIndex: 1,
});

export const MainLogoBlock = styled.div`
  cursor: pointer;
  & > img {
    display: block;
  }
`;

export const StyledButton = styled.button`
  width: ${(props) => props.widthSize || "174px"};
  height: ${(props) => props.heightSize || "56px"};
  border-radius: 4px;
  border: 0px;
  background-color: #0c5ccc;
  font-weight: 500;
  font-size: 20px;
  font-size: ${(props) => props.fontSize || "20px"};
  color: ${(props) => props.colorType || "#ffffff"};
  cursor: pointer;

  :hover {
    background-color: #86cde7;
  }

  :active {
    background-color: #0c5ccc;
  }

  :focus {
    outline: none;
  }

  :disabled {
    background-color: #86cde7;
    color: #fff;
    cursor: default;
    border-color: #f2f4f5;
  }
`;

export const StyledHeaderMenuButton = styled(DehazeIcon)`
  cursor: pointer;
  padding: 17px 0;
  display: block !important;
`;

export const StyledSearchButton = styled(StyledButton)`
  width: 100%;
  height: 100%;
  display: none;

  @media (min-width: 600px) {
    display: inline-block;
  }
`;

export const StyledMobileSearchButton = styled(StyledButton)`
  width: 100%;
  min-height: 52px;
  display: inline-block;

  @media (min-width: 600px) {
    display: none;
  }
`;

export const StyledPaymentButton = styled(StyledButton)`
  font-size: 18px;
`;

export const StyledVoidButton = styled(StyledButton)`
  font-size: 16px;
  width: 206px;
`;

export const StyledSupportButton = styled(StyledButton)`
  background-color: #4caf50;
  font-size: 16px;
  width: 206px;

  :hover {
    background-color: #3fb544;
  }

  :active {
    background-color: #388c3c;
  }

  :focus {
    outline: none;
  }
`;

export const StyledTicketsOptionsButton = styled(StyledButton)`
  background-color: #ffffff;
  border-color: #ffffff;
  font-size: 16px;
  width: 206px;
  color: #212121;

  :hover {
    background-color: #fafbfc;
  }

  :active {
    background-color: #fafbfc;
  }

  :focus {
    outline: none;
  }
`;

export const SearchBackground = styled.div`
  background-image: url(${bgImage});
  margin-bottom: 30px;
  background-size: cover;
  transform: translateY(-65px);
`;

export const SearchResultBackground = styled.div`
  background-image: url(${bgImage});
  width: 100%;
  background-size: cover;
  position: ${(props) => (props.minimizeSearch ? "sticky" : "relative")};
  top: ${(props) => (props.minimizeSearch ? "58px" : "0")};
  left: 0;
  z-index: 40;
  transition: transform 0.3s ease;

  &.hideHeader {
    transform: translateY(${(props) => (props.minimizeSearch ? "-58px" : "0")});
    transition: transform 0.3s ease;
  }

  @media screen and (min-width: 1280px) {
    position: sticky;
    top: 65px;

    &.hideHeader {
      transform: translateY(-65px);
    }
  }
`;

export const StyledInput = styled.input`
  ${(props) =>
    props.isPadding &&
    `
        padding-left: 16px;
        padding-top: 30px;
        padding-bottom: 8px;
    `}

  height: ${(props) => props.heightSize || "52px"};
  width: ${(props) => props.widthSize || "297px"};

  border-radius: 4px;
  border: solid ${(props) => (props.borderColor ? "2px" : "1px")}
    ${(props) => props.borderColor || "#34ccec"};
  background-color: ${(props) => (props.disabled ? "#f2f4f5" : "#ffffff")};
  font-size: 18px;
  color: ${(props) => (props.disabled ? "#b9c1c5" : "#212121")};
  text-transform: ${(props) => props.textTransform || "none"};

  :focus {
    outline: none;
    border: solid 1px #0c5ccc;
  }
`;

export const StyledLink = styled(Link)`
  font-size: 16px;
  color: #ffffff;
  text-decoration: none;
`;

export const InvalidMessage = styled.div`
  font-size: 14px;
  color: #c62828;
`;

export const WarningMessage = styled.div`
  font-size: 14px;
  color: #0c5ccc;
`;

export const ErrorsFieldBlock = styled.div`
  text-align: left;
`;

export const FormFieldLabel = styled.label`
  font-size: 12px;
  color: #9da8ae;
  position: absolute;
  top: 10px;
  left: 16px;
`;

export const FormGroup = styled.div`
  position: relative;
  width: 100%;
`;

export const Arrow = styled.div`
  width: 0;
  height: 0;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  display: inline-block;
  margin-left: 8px;
  cursor: pointer;
`;

export const ArrowDown = styled(Arrow)`
  border-top: 5px solid ${(props) => (props.isInvert ? "#212121" : "#ffffff")};
`;

export const ArrowUp = styled(Arrow)`
  border-bottom: 5px solid
    ${(props) => (props.isInvert ? "#212121" : "#ffffff")};
`;

export const SelectArrowDown = styled(ArrowDown)`
  margin-left: 0;
`;

export const InvertSelectArrowDown = styled(SelectArrowDown)`
  border-top: 8px solid #212121;
`;

export const LinkText = styled.div`
  display: inline-block;
`;

export const ResponsiveInput = styled.input`
  ${(props) =>
    props.isPadding &&
    `
        padding-left: 16px;
        padding-top: 30px;
        padding-bottom: 8px;
    `}

  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  border: solid ${(props) => (props.borderColor ? "2px" : "1px")}
    ${(props) => props.borderColor || "#34ccec"};
  background-color: ${(props) => (props.disabled ? "#f2f4f5" : "#ffffff")};
  font-size: 18px;
  color: ${(props) => (props.disabled ? "#b9c1c5" : "#212121")};
  text-transform: ${(props) => props.textTransform || "none"};

  :focus {
    outline: none;
    border: solid 1px #0c5ccc;
  }
`;
