import React from "react";
import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import TextField from "@material-ui/core/TextField";

const theme = createMuiTheme({
  overrides: {
    MuiInputLabel: {
      root: {
        color: "#9da8ae",
        paddingLeft: 8,
        "&$focused": {
          color: "#9da8ae",
        },
      },
      formControl: {
        width: "120%",
      },
    },
  },
});

const useStylesInput = makeStyles({
  root: {
    border: "1px solid #e2e2e1",
    overflow: "hidden",
    borderRadius: 4,
    paddingLeft: 8,
    backgroundColor: "#ffffff",
    color: "#212121",
    fontSize: 18,
    "&:hover": {
      backgroundColor: "#ffffff",
    },
  },
  focused: {
    backgroundColor: "#ffffff",
    borderColor: "#0c5ccc",
  },
});

function InputTextField(props) {
  const classes = useStylesInput();
  const { InputProps, input } = props;

  return (
    <TextField
      {...props}
      {...input}
      autoComplete="off"
      InputProps={{
        ...InputProps,
        classes,
        disableUnderline: true,
      }}
    />
  );
}

export default function InputUI(props) {
  const { onChange } = props;

  const handelChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <ThemeProvider theme={theme}>
      <InputTextField
        {...props}
        onChange={handelChange}
        variant="filled"
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
      />
    </ThemeProvider>
  );
}

InputUI.defaultProps = {
  onChange: () => {},
};
