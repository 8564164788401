import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import RemoveIcon from "@material-ui/icons/Remove";

const useStyles = makeStyles({
  rootContainer: {
    padding: "3px 0 6px",
    alignItems: "center",
    justifyContent: "center",
  },
  itemContainer: {
    fontWeight: "500",
    height: "32px",
    position: "relative",
    width: "40%",
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
  },
  label: {
    width: "60%",
  },
  button: {
    padding: 0,
    outline: "none",
    width: "32px",
    height: "32px",
    borderRadius: "4px",
    fontSize: "30px",
    margin: "0 6px",
    backgroundColor: "#86cde7",

    "&:hover": {
      backgroundColor: "#86cde7",
    },
    "&:disabled": {
      backgroundColor: "#f2f4f5",
    },
    "&:active": {
      backgroundColor: "#86cde7",
    },
  },
  buttonIcon: {
    position: "relative",
  },
});

const StyledNumberInput = styled.input`
  text-align: center;
  width: 12px;
  font-size: 18px;
  color: #000000;
  border: none;
  background-color: #ffffff;
  padding: 0 3px;
  margin: 0;
`;

function NumberInput(props) {
  const classes = useStyles();
  const { input, disabledInput, isMaximum, value, label, step, min, onChange } =
    props;

  const changeValue = (data) => {
    const newValue = value + data * step;
    const correctValue = newValue < min ? min : newValue;

    onChange(correctValue);
  };

  const handleMinus = () => {
    changeValue(-1);
  };

  const handlePlus = () => {
    changeValue(1);
  };

  const minusButtonDisabled = () => {
    return value === min;
  };

  return (
    <Grid container className={classes.rootContainer}>
      <Grid item className={classes.label}>
        {label}
      </Grid>
      <Grid item className={classes.itemContainer}>
        <IconButton
          className={classes.button}
          onClick={handleMinus}
          disabled={minusButtonDisabled()}
          disableRipple
        >
          <RemoveIcon
            className={classes.buttonIcon}
            style={{
              color: minusButtonDisabled() ? "#b9c1c5" : "#0c5ccc",
            }}
          />
        </IconButton>
        <StyledNumberInput
          {...input}
          value={value}
          type="text"
          disabled={disabledInput}
        />
        <IconButton
          className={classes.button}
          onClick={handlePlus}
          disabled={isMaximum}
          disableRipple
        >
          <AddIcon
            className={classes.buttonIcon}
            style={{
              color: isMaximum ? "#b9c1c5" : "#0c5ccc",
            }}
          />
        </IconButton>
      </Grid>
    </Grid>
  );
}

NumberInput.defaultProps = {
  step: 1,
  min: 0,
  value: 0,
  disabledInput: true,
  label: null,
  isMaximum: false,
};

export default NumberInput;
