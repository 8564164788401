import { makeStyles } from "@material-ui/core/styles";
import loader from "../../images/loader.gif";

export const useStyles = makeStyles({
  rootContainerLoadingBlock: {
    minHeight: "600px",
  },
  rootBlockLoadingBlock: {
    height: "600px",
  },
  titleLoadingBlock: {
    marginBottom: "12px",
    color: "#212121",
    fontWeight: "500",
    fontSize: "24px",
  },
  loadingSniper: {
    width: "80px",
    height: "80px",
    backgroundImage: `url(${loader})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "80px",
    backgroundColor: "transparent",
  },
  circularProgress: {
    color: "#86cde7",
  },
});
