import React from "react";
import mainLogo from "../../images/mainLogo/smartgo1.png";
import { MainLogoBlock } from "../../styles/common/index";
import { uploadHost } from "../../config/config";

function MainLogo(props) {
  const { history, withReload, withLogo, partnerLogo } = props;

  const handleOnClick = () => {
    history.push({
      pathname: "/",
    });

    if (withReload) {
      window.location.reload();
    }
  };

  if (!withLogo) {
    return null;
  }

  const src = partnerLogo ? `${uploadHost}${partnerLogo}` : mainLogo;

  return (
    <MainLogoBlock onClick={() => handleOnClick()}>
      <img alt="logo-img" src={src} height="40px" width="120px" />
    </MainLogoBlock>
  );
}

export default MainLogo;
