import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import noun from "plural-ru";
import { LangContext } from "../../../../providers/LangProvider";

const useStyles = makeStyles({
  valuesContainer: {
    display: "flex",

    "@media (max-width: 960px)": {
      marginBottom: "8px",
    },
  },
  timelimitValueBlock: {
    width: "60px",
    height: "48px",
    borderRadius: "8px",
    backgroundColor: "#f2f4f5",
    display: "flex",
    flexDirection: "column",
    marginRight: "8px",
    textAlign: "center",
  },
  timelimitValueItem: {
    paddingTop: "3px",
    fontWeight: "500",
    fontSize: "20px",
  },
  timelimitLabelItem: {
    color: "#000000",
    fontSize: "12px",
  },
  timelimitLabelsContainer: {
    paddingTop: "12px",
    fontSize: "14px",
    color: "#7e878b",
  },
  timelimitExpired: {
    paddingTop: "12px",
    fontWeight: "500",
    fontSize: "16px",
    color: "#0c5ccc",
  },
});

export default function TimelimitCounter(props) {
  const classes = useStyles();
  const { t } = useContext(LangContext);

  const { timelimit, disableTimer } = props;
  const [timelimitSecond, setTimelimitSecond] = useState(timelimit);

  useEffect(() => {
    let counter = setTimeout(
      () => setTimelimitSecond(timelimitSecond - 1),
      1000
    );

    return () => {
      clearTimeout(counter);
    };
  });

  if (timelimitSecond < 0) {
    disableTimer();

    return (
      <div className={classes.timelimitExpired}>{t.payment_timed_out}</div>
    );
  }

  let seconds = Math.floor(timelimitSecond % 60);
  let minutes = Math.floor((timelimitSecond / 60) % 60);
  let hours = Math.floor((timelimitSecond / (60 * 60)) % 24);
  let days = Math.floor(timelimitSecond / (60 * 60 * 24));

  return (
    <div className={classes.valuesContainer}>
      <div className={classes.timelimitValueBlock}>
        <div className={classes.timelimitValueItem}>{days}</div>
        <div className={classes.timelimitLabelItem}>
          {getDaysString(days, t)}
        </div>
      </div>
      <div className={classes.timelimitValueBlock}>
        <div className={classes.timelimitValueItem}>{hours}</div>
        <div className={classes.timelimitLabelItem}>
          {getHoursString(hours, t)}
        </div>
      </div>
      <div className={classes.timelimitValueBlock}>
        <div className={classes.timelimitValueItem}>{minutes}</div>
        <div className={classes.timelimitLabelItem}>
          {getMinutesString(minutes, t)}
        </div>
      </div>
      <div className={classes.timelimitValueBlock}>
        <div className={classes.timelimitValueItem}>{seconds}</div>
        <div className={classes.timelimitLabelItem}>
          {getSecondsString(seconds, t)}
        </div>
      </div>
    </div>
  );
}

const getDaysString = (count, t) => {
  return noun(count, t.day_one, t.day_more, t.days);
};

const getHoursString = (count, t) => {
  return noun(count, t.hour_one, t.hour_more, t.hours);
};

const getMinutesString = (count, t) => {
  return noun(count, t.minute_one, t.minute_more, t.minutes);
};

const getSecondsString = (count, t) => {
  return noun(count, t.second_one, t.second_more, t.seconds);
};
