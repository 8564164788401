import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((props) => {
  return {
    airportFieldContainer: {
      position: "relative",
    },
    searchSubmitBlock: {
      width: "100%",

      "@media (min-width:600px)": {
        paddingLeft: "16px",
      },
    },
    searchSubmitButtonsBlock: {
      display: "none",
      height: "100%",
      alignItems: "center",

      "@media (min-width: 600px)": {
        display: "flex",
      },
    },
    dateFromBlock: {
      paddingLeft: 0,

      "@media (min-width:1280px)": {
        paddingLeft: "16px",
      },
    },
    airportRoutesContainer: {
      position: "relative",
      marginBottom: "8px",

      "@media (min-width:600px)": {
        marginBottom: "16px",
      },

      "@media (min-width:1280px)": {
        marginBottom: 0,
      },
    },
    airportFromFieldBlock: {
      marginBottom: 0,

      "@media (min-width:960px)": {
        marginBottom: 0,
      },

      "@media (min-width:1275px)": {
        marginBottom: 0,
      },
    },
    airportToFieldBlock: {
      marginBottom: 0,

      "@media (min-width:960px)": {
        marginBottom: 0,
      },

      "@media (min-width:1275px)": {
        marginBottom: 0,
      },
    },
    addRouteButtonBlock: {
      display: "none",
      width: "100%",
      height: "50px",
      alignItems: "center",

      "@media (min-width: 600px)": {
        display: "flex",
      },
    },
    flightOptionsContainer: {
      width: "100%",
      textAlign: "left",
    },
    flightOptionsBlock: {
      flexDirection: "column",

      "@media (min-width: 600px)": {
        flexDirection: "row",
      },
    },
    mobileSearchSubmit: {
      width: "100%",
      display: "block",

      "@media (min-width: 600px)": {
        display: "none",
      },
    },
    flightType: {
      position: "relative",
      margin: "10px 0",

      "@media (min-width: 600px)": {
        margin: "0 30px 0 0",
      },

      "@media (min-width: 960px)": {
        margin: "0 50px 0 0",
      },
    },
    passengersSelector: {
      marginRight: 0,

      "@media (min-width: 600px)": {
        marginRight: "30px",
      },

      "@media (min-width: 960px)": {
        marginRight: "50px",
      },
    },
    flightClass: {
      position: "relative",
      margin: "10px 0",

      "@media (min-width: 600px)": {
        margin: "0 30px 0 0",
      },

      "@media (min-width: 960px)": {
        margin: "0 50px 0 0",
      },
    },
    changeRoutesCountButtons: {
      display: "flex",
      height: "50px",
      position: "relative",
      width: "100%",
      alignItems: "center",

      "@media (min-width:600px)": {
        display: "none",
      },
    },
    removeButtonBlockForMobile: {
      marginLeft: "auto",
    },
    addRouteButtonTitle: {
      paddingLeft: "8px",
      paddingRight: "8px",
    },
    dateContainer: {
      position: "relative",
    },
    searchMinimizedContainer: {
      display: "flex",
      width: "100%",
      padding: "8px 16px 8px 0",
      backgroundColor: "#fff",
      borderRadius: "4px",
      boxSizing: "border-box",
    },
    searchMinimizedItem: {
      padding: "0 16px",
      borderRight: "1px solid #cdd4de",
      width: "115px",
      boxSizing: "border-box",

      "&:nth-last-child(1)": {
        borderRight: "none",
      },
    },
    searchMinimizedItemFlight: {
      textAlign: "left",
      lineHeight: 1,
      fontWeight: "bold",
      marginBottom: "6px",
      whiteSpace: "nowrap",
    },
    searchMinimizedItemTime: {
      lineHeight: 1,
      fontSize: "14px",
      textAlign: "left",
    },
    searchHiddenRoutesCount: {
      position: "absolute",
      top: "16px",
      right: "26px",
      fontSize: "24px",
    },
    minimizeButton: {
      padding: "16px 0 8px",
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
    minimizeButtonIcon: {
      padding: "8px 0",

      "&:hover": {
        cursor: "pointer",
      },
    },
    bannersBlock: {
      marginTop: "-32px",
      marginBottom: "64px",
    },
    bannersImage: {
      width: "100%",
    },
    carouselBlock: {
      marginTop: "8px",
      marginBottom: "32px",
    },
    carouselItemBlock: {
      height: "120px",
    },
    carouselItem: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "24px",
      height: "100%",
      boxSizing: "border-box",
    },
    carouselItemImage: {
      width: "100%",
      display: "block",
    },
    subscriptionContainer: {
      backgroundColor: "#fff",
      padding: "16px 0",
      marginBottom: "32px",
    },
    subscriptionTitle: {
      fontSize: "20px",
      fontWeight: "bold",
      marginBottom: "16px",
    },
    subscriptionForm: {
      display: "flex",
      alignItems: "center",
    },
    emailInput: {
      width: "100%",
      boxSizing: "border-box",
      padding: "12px 8px",
      fontSize: "20px",
    },
    subscriptionButton: {
      borderRadius: "4px",
      backgroundColor: "#0c5ccc",
      fontWeight: 500,
      fontSize: "20px",
      color: "#fff",
      cursor: "pointer",
      width: "100%",
      display: "inline-block",
      height: "100%",
    },
    successResult: {
      color: "#3eab50",
    },
    errorResult: {
      color: "#e81b1b",
    },
    circularProgress: {
      color: "#fff",
    },
    flightHistory: {
      position: "relative",
      margin: "6px 0 0",

      "@media (max-width: 960px)": {
        margin: "0 0 8px -8px",
      },
    },
    dialogContent: {
      padding: "0",
    },
    tabContent: {
      padding: "8px 16px",
    },
    titleStyles: {
      fontSize: "14px",
      fontWeight: 500,
      borderBottom: "1px solid #e4e7e8",
      padding: "16px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    closeButtonStyles: {
      padding: 0,
    },
    buyButtonStyles: {
      padding: "12px",
      backgroundColor: "#0c5ccc",
      fontWeight: 600,
      color: "#fff",

      "&:hover": {
        backgroundColor: "#86cde7",
      },
    },
    actionsStyles: {
      padding: "0 16px 16px",
    },
    transfersBetweenAirportsWarning: {
      paddingTop: "8px",
      color: "#ff531d",
    },
    mainHistoryBlock: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      padding: "10px",
    },
    historyButton: {
      color: "#ffffff",
      textTransform: "capitalize",
      fontSize: "16px",
      fontWeight: "inherit",
    },
    details: {
      width: "100%",
    },
    routes: {
      fontWeight: "bold",
      cursor: "pointer",
      display: "flex",
    },
    searchedTime: {
      display: "flex",
      marginBottom: "8px",
    },
    time: {
      marginLeft: "5px",
    },
    passengers: {
      display: "flex",
      alignItems: "center",
      marginBottom: "8px",
    },
    passengersInfo: {
      fontSize: "12px",
      marginLeft: "4px",
    },
    paper: {
      margin: "16px",
    },
    paperFullWidth: {
      width: "calc(100% - 32px)",
      borderRadius: 0,
    },
    deleteIcon: {
      backgroundColor: "#dc3545",
      marginLeft: "3px",
      height: "25px",
      color: "#fff",
      fontWeight: "inherit",
      fontSize: "12px",
      "&:hover": {
        color: "#dc3545",
      },
    },
    openBrowser: {
      backgroundColor: "#0c5ccc",
      height: "25px",
      color: "#fff",
      fontWeight: "inherit",
      fontSize: "12px",
      "&:hover": {
        color: "#86cde7",
      },
    },
    searchButtons: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: "8px",
    },
    footer: {
      display: "flex",
      justifyContent: "center",
      margin: "16px",
    },
    clearAllHistory: {
      backgroundColor: "#0c5ccc",
      height: "30px",
      color: "#fff",
      fontWeight: "inherit",
      fontSize: "14px",
      "&:hover": {
        color: "#86cde7",
      },
    },
  };
});
