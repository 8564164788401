import styled from "styled-components";

export const HeaderContainer = styled.div`
  text-align: center;
  width: 100%;
  color: #fff;

  ${(props) =>
    props.headerWithoutImg &&
    `
        // background-color: #0c5ccc;
    `}

  @media screen and (max-width: 960px) {
    background-color: #0c5ccc;
  }
`;
